document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  // $('.toast').toast('show')
  // $('.toast').toast({ autohide: false })

  $('[data-dismiss=toast]').click(function () {
    $(this).closest('.toast').toast('hide');
  });

  $('.select2-basic').select2();

  if ($('body').hasClass('text-sm') || $('.brand-link').hasClass('text-sm')) {
    $('.brand-fa-icon').removeClass('large-brand-fa-icon');
    $('.brand-fa-icon').addClass('small-brand-fa-icon');
  } else {
    $('.brand-fa-icon').addClass('large-brand-fa-icon');
    $('.brand-fa-icon').removeClass('small-brand-fa-icon');
  }

  if ($('[data-select2-dynanic-tags]').length) {
    $('[data-select2-dynanic-tags]').select2({
      tags: true,
      tokenSeparators: [','],
      ajax: {
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            search: params.term,
            page: params.page
          };
        },
        processResults: function (data, params) {
          return {
            results: $.map(data, function(element, i) {
              return { id: element.name, text: element.name };
            })
          };
        },
        cache: true
      },
      minimumInputLength: 0,
      escapeMarkup: function (markup) { return markup; },
      theme: 'bootstrap4',
      allowClear: true,
      placeholder: 'Please select an option'
    });
  }
});
