require('jsoneditor');

const JSONEditor = require('jsoneditor');

// specific for url
const schemaConfig =
{
  type: 'object',
  properties: {
    url: {
      type: ['string', 'array'],
      description: 'basket/cart url'
    }
  },
  required: [
    'url'
  ]
};

document.addEventListener('turbolinks:load', () => {
  // always have different ids
  // specific for config
  function initJsonEditorContainer (container) {
    const previewOnly = container.dataset.jsonPreviewOnly === 'true';
    const validateSchema = container.dataset.jsonValidateSchema === 'true';
    const id = container.dataset.jsonEditorContainer;
    const contentContainer = document.querySelector(`[data-json-editor-content="${id}"]`);
    const maximizeButton = document.querySelector(`[data-json-editor-maximize="${id}"]`);
    let json;

    if (maximizeButton != null) {
      // listner to be able to expand editor area
      const box = document.querySelector(`[data-json-editor-box="${id}"]`);
      maximizeButton.addEventListener('click', function() {
        box.classList.toggle('max-box');
        box.classList.toggle('min-box');
      });
    }

    try {
      json = JSON.parse(contentContainer.textContent);
      if (json == null) { contentContainer.value = '{}'; }
    } catch (error) {
      // json is empty (when its new)
      contentContainer.value = '{}';
      console.log(contentContainer);
      json = {};
    }
    const options = {
      mode: previewOnly ? 'preview' : 'code',
      modes: previewOnly ? ['view', 'preview'] : ['code', 'form', 'text', 'tree'], // allowed modes
      schema: validateSchema ? schemaConfig : '',
      onChangeText: function (json) {
        contentContainer.value = json;
      }
    };
    const editor = new JSONEditor(container, options, json);
    editors[`editor_${id}`] = editor;
  }

  const editors = {};
  global.editors = editors;
  window.editors = editors;

  editors.addConfig = initJsonEditorContainer;
  document.querySelectorAll('[data-json-editor-container]').forEach(container => {
    initJsonEditorContainer(container);
  });
});
