require('pc-bootstrap4-datetimepicker');

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

document.addEventListener('turbolinks:load', function() {
  $('.datetimepicker').datetimepicker({
    format: 'DD/MM/YYYY'
  });

  $('.datetimepicker_minutes_seconds').datetimepicker({
    format: 'DD/MM/YYYY HH:mm:ss'
  });

  $('.datetimepicker_minutes').datetimepicker({
    format: 'DD/MM/YYYY HH:mm'
  });

  $('.datetimepicker_years_mode').datetimepicker({
    format: 'DD/MM/YYYY',
    viewMode: 'years'
  });

  $('.datetimepicker_minutes_linked1').datetimepicker({
    format: 'DD/MM/YYYY HH:mm'
  });
  $('.datetimepicker_minutes_linked2').datetimepicker({
    format: 'DD/MM/YYYY HH:mm',
    useCurrent: false
  });
  $('.datetimepicker_linked1').datetimepicker({
    format: 'DD/MM/YYYY'
  });
  $('.datetimepicker_linked2').datetimepicker({
    format: 'DD/MM/YYYY',
    useCurrent: false
  });
  $('.datetimepicker_minutes_linked1').on('dp.change', function (e) {
    $('.datetimepicker_minutes_linked2').data('DateTimePicker').minDate(new Date(e.date + (24 * 60 * 60 * 1000)));
  });
  $('.datetimepicker_minutes_linked2').on('dp.change', function (e) {
    $('.datetimepicker_minutes_linked1').data('DateTimePicker').maxDate(new Date(e.date - (24 * 60 * 60 * 1000)));
  });

  $('.datetimepicker_linked1').on('dp.change', function (e) {
    $('.datetimepicker_linked2').data('DateTimePicker').minDate(new Date(e.date + (24 * 60 * 60 * 1000)));
  });
  $('.datetimepicker_linked2').on('dp.change', function (e) {
    $('.datetimepicker_linked1').data('DateTimePicker').maxDate(new Date(e.date - (24 * 60 * 60 * 1000)));
  });
});
