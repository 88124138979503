// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/admin';
import 'admin/main';
import 'admin/datetimepicker';
import 'vanilla-nested';
import '../hyped_listing';
import 'admin/jsoneditor';
import 'chartkick/chart.js';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('trix');
require('@rails/actiontext');

const jQuery = require('jquery');
// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('bootstrap');
require('select2');
require('moment');
require('pc-bootstrap4-datetimepicker');
// require('jsoneditor');
